// import ScrollService from './NativeScroll'
import ScrollService from './VirtualScroll'

ScrollService.getScrollOffset = () => {
    // if (window.matchMedia(queries.smallWideMax).matches) {
    //     const opener = document.querySelector('.Header')
    //     if (!opener) {
    //         return 100
    //     }
    //     const openerRect = opener.getBoundingClientRect()
    //     return openerRect.top + openerRect.height + 10
    // } else {
    //     return 100
    // }
    return 0
}

export const disableScroll = () => ScrollService.disableScroll()
export const enableScroll = () => ScrollService.enableScroll()
export const getScroll = () => ScrollService.getScroll()
export const getScrollTop = () => ScrollService.getScrollTop()
export const scrollToElement = (element, options) => ScrollService.scrollToElement(element, options)

export default ScrollService